<template>
  <v-app>
    <v-dialog
        v-model="dialog"
        max-width="800"
    >
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            Additional assessments
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <div class="row">

              <div class="col-12">
                <v-switch
                    v-model="exam.has_additional_assessment"
                    label="Additional assessment"
                >
                </v-switch>
              </div>

              <div class="col-12" v-if="exam.id && exam.has_additional_assessment">
                <v-row>

                  <div class="col-12">
                    <v-select
                        multiple chips deletable-chips
                        :items="additional_assessments"
                        v-model="exam.additional_assessment"
                        outlined
                        dense
                        label="Select additional assessments"

                        clearable
                    >
                    </v-select>
                  </div>
                  <div class="col-12" v-if="exam.id && exam.has_additional_assessment">
                    <v-select
                        multiple chips small-chips deletable-chips
                        :items="additional_assessments"
                        v-model="exam.internal_evaluation"
                        outlined
                        dense
                        label="Internal evaluation"
                        item-text="title"
                        item-value="id"
                        clearable
                    >
                    </v-select>
                  </div>
                </v-row>

              </div>
            </div>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
              <v-btn
                  class="btn btn-standard cancel-btn"
                  depressed
                  @click="closeDialog"
              >Cancel
              </v-btn>
              <v-btn
                  class="text-white ml-2 btn btn-primary"
                  depressed
                  @click="createAndUpdate"
                  :loading="isBusy"
              >Update
              </v-btn>

        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-app>

</template>
<script>
import {required} from "vuelidate/lib/validators";
import "vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import ExamService from "@/core/services/exam/ExamService";
import GradePointService from "@/core/services/level/program/grade-point/GradePointService";

const levelService = new LevelService()
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
const examService = new ExamService();
const pointService = new GradePointService();

export default {
  name: "ManageAdditionalAssessment",
  components: {},
  data() {
    return {
      dialog:false,
      isBusy:false,
      exam: {
        title: null,
        exam_year: null,
        type: 'terminal',
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        /*  duration: null,
          duration_unit: 'hours',*/
        has_additional_assessment: false,
        exam_location: 'in-premise',
        location_description: null,
        is_active: true,
        isBusy: false,
        is_classes_exam: false,
        applicable_classes: null
      },
      additional_assessments:[],

    };
  },
  mounted() {

  },
  methods: {
    showModal(exam = {}) {
      if (exam.id) {
        this.edit = true
        this.exam = exam
        this.getProgram()

      }
      this.dialog = true

    },



    getProgram() {
      programService
          .show(this.exam.program_id)
          .then(response => {
            if (response.data.program && response.data.program.additional_assessments)
              this.additional_assessments = response.data.program.additional_assessments
            });
    },



    createAndUpdate() {
      this.updateExam();
    },
    closeDialog(){
      this.dialog=false;
      this.resetForm();
    },

    updateExam() {
      this.isBusy = true;

      examService.update(this.exam.id, this.exam).then(response => {
        this.isBusy = false;

        this.$snotify.success("Information updated");
        this.closeDialog();
      }).catch(error => {

        this.$snotify.error("Something went wrong")
      }).finally(final => {
        this.isBusy = false
      });
    },
    resetForm() {
      this.exam = {
        title: null,
        type: null,
        academic_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        /*  duration: null,
          duration_unit: null,*/
        has_additional_assessment: false,
        exam_location: null,
        location_description: null,
        is_classes_exam: false,
        applicable_classes: null,
        is_active: true
      }



      this.$emit('refresh')
    }
  }
};
</script>
<style>
.v-application--wrap {
  min-height: 0 !important;
}
</style>
