<template>
  <v-app>

    <v-dialog
        title="Add/update exam"
        v-model="dialog"
        width="900"
    >
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            Course setup for examining
            <hr>

          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="hideModal">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <div class="row ">

            <div class="col-md-12">
              These settings will not update marks on actual course information available for the program. These settings are only designed to change marks for this exam only.
            </div>
            <div class="col-12" v-if="courses && courses.length > 0">
              <v-select
                  :items="courses"
                  @change="setCourse()"
                  dense
                  item-text="display_text_with_subject_code"
                  item-value="id"
                  label="Course"
                  outlined
                  return-object
                  v-model="selectedCourse"
              >
              </v-select>
            </div>
            <div class="col-12" v-if="courses && courses.length == 0">
              All of the courses has been assigned
            </div>
            <div class="col-3" v-if="selectedCourse">
              <v-text-field dense label="Theory full mark" outlined
                            placeholder="Theory full mark"
                            v-model="selected_exam_course.theory_mark"></v-text-field>
              <span class="text-danger"
                    v-if="$v.selected_exam_course.theory_mark.$error">This information is required</span>

            </div>

            <div class="col-3" v-if="selectedCourse">
              <v-text-field dense label="Theory pass mark" outlined
                            placeholder="Theory pass mark"
                            v-model="selected_exam_course.theory_pass_mark"></v-text-field>
              <span class="text-danger" v-if="$v.selected_exam_course.theory_pass_mark.$error">This information is required</span>
            </div>

            <div class="col-3" v-if="selectedCourse">
              <v-text-field dense label="Practical full mark" outlined
                            placeholder="Practical full mark"
                            v-model="selected_exam_course.practical_mark"></v-text-field>
              <span class="text-danger" v-if="selectedCourse.has_practical && $v.selected_exam_course.practical_mark.$error">This information is required</span>

            </div>

            <div class="col-3" v-if="selectedCourse">
              <v-text-field dense label="Practical pass mark" outlined
                            placeholder="Practical pass mark"
                            v-model="selected_exam_course.practical_pass_mark"></v-text-field>
              <span class="text-danger" v-if="selectedCourse.has_practical && $v.selected_exam_course.practical_pass_mark.$error">This information is required</span>
            </div>

            <div class="col-md-6" v-if="selectedCourse">
              <label>Start date</label>
              <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      dense
                      label="Start date"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      v-model="selected_exam_course.start_date"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    :min="exam.start_date"
                    @input="menu = false"
                    v-model="selected_exam_course.start_date"
                ></v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.selected_exam_course.start_date.$error">This information is required</span>
            </div>



            <div class="col-md-6" v-if="selectedCourse">
              <label>End date</label>
              <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="menu2"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="selected_exam_course.end_date"
                      label="End date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                    :min="exam.start_date"
                    @input="menu2 = false"
                    v-model="selected_exam_course.end_date"
                ></v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.selected_exam_course.end_date.$error">This information is required</span>
            </div>

            <v-col  md="4" v-if="selectedCourse">
              Has shift wise timing ?
              <v-switch @change="handleShiftWiseTiming"
                  v-model="selected_exam_course.has_shiftwise_timing"
                  :label="selected_exam_course.has_shiftwise_timing ? 'Yes' : 'No'"
                  color="primary"
              ></v-switch>
            </v-col>
            <v-col cols="6" md="8" v-if="selectedCourse && selected_exam_course.has_shiftwise_timing">
              <v-autocomplete  label="Shift type" dense outlined multiple small-chips deletable-chips
                               item-value="value" v-model="selected_exam_course.shift_type" item-text="text" :items="shift_types">
              </v-autocomplete>
              <span class="text-danger" v-if="$v.selected_exam_course.shift_type.$error">This information is required</span>
            </v-col>

            <v-col  cols="6" md="8" v-if="selectedCourse && !selected_exam_course.has_shiftwise_timing"></v-col>
            <div class="col-md-6" v-if="selectedCourse &&  !selected_exam_course.has_shiftwise_timing">
              <label>Start time</label>
              <v-text-field dense
                            outlined type="time" v-model="selected_exam_course.start_time"></v-text-field>
              <span class="text-danger" v-if="$v.selected_exam_course.start_time.$error">This information is required</span>
            </div>
            <div class="col-md-6" v-if="selectedCourse && !selected_exam_course.has_shiftwise_timing">
              <label>End time</label>
              <v-text-field dense outlined
                            type="time" v-model="selected_exam_course.end_time"></v-text-field>
              <span class="text-danger" v-if="$v.selected_exam_course.end_time.$error">This information is required</span>
            </div>
            <div class="col-md-6" v-if="selectedCourse &&  selected_exam_course.has_shiftwise_timing && isValueInShiftType('morning')">
              <label>Morning start time</label>
              <v-text-field dense
                            outlined type="time" v-model="selected_exam_course.morning_start_time"></v-text-field>
              <span class="text-danger" v-if="$v.selected_exam_course.morning_start_time.$error">This information is required</span>
            </div>
            <div class="col-md-6" v-if="selectedCourse && selected_exam_course.has_shiftwise_timing && isValueInShiftType('morning')">
              <label>Morning  end time</label>
              <v-text-field dense outlined
                            type="time" v-model="selected_exam_course.morning_end_time"></v-text-field>
              <span class="text-danger" v-if="$v.selected_exam_course.morning_end_time.$error">This information is required</span>
            </div>
            <div class="col-md-6" v-if="selectedCourse &&  selected_exam_course.has_shiftwise_timing && isValueInShiftType('day')">
              <label>Day start time</label>
              <v-text-field dense
                            outlined type="time" v-model="selected_exam_course.day_start_time"></v-text-field>
              <span class="text-danger" v-if="$v.selected_exam_course.day_start_time.$error">This information is required</span>
            </div>
            <div class="col-md-6" v-if="selectedCourse && selected_exam_course.has_shiftwise_timing && isValueInShiftType('day')">
              <label>Day end time</label>
              <v-text-field dense outlined
                            type="time" v-model="selected_exam_course.day_end_time"></v-text-field>
              <span class="text-danger" v-if="$v.selected_exam_course.day_end_time.$error">This information is required</span>
            </div>
            <div class="col-md-6" v-if="selectedCourse &&  selected_exam_course.has_shiftwise_timing && isValueInShiftType('evening')">
              <label>Evening start time</label>
              <v-text-field dense
                            outlined type="time" v-model="selected_exam_course.evening_start_time"></v-text-field>
              <span class="text-danger" v-if="$v.selected_exam_course.evening_start_time.$error">This information is required</span>
            </div>
            <div class="col-md-6" v-if="selectedCourse && selected_exam_course.has_shiftwise_timing && isValueInShiftType('evening')">
              <label>Evening end time</label>
              <v-text-field dense outlined
                            type="time" v-model="selected_exam_course.evening_end_time"></v-text-field>
              <span class="text-danger" v-if="$v.selected_exam_course.evening_end_time.$error">This information is required</span>
            </div>
            <div class="col-12" v-if="selectedCourse">
              <v-text-field dense label="Exam day order" outlined placeholder="Exam day order"
                            v-model="selected_exam_course.position"></v-text-field>
            </div>
            <div class="col-md-12" v-if="selectedCourse">
              <!--              <label>Status</label>-->
              <v-select :items="statuses"
                item-text="name" item-value="value" dense
                label="Status" outlined v-model="selected_exam_course.status">
              </v-select>

            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              @click="hideModal()"
              class="btn btn-standard cancel-btn"
              text
          >
            Cancel
          </v-btn>
          <v-btn
              @click="saveOrUpdate"
              class="text-white btn btn-primary"
              text
              v-if="selectedCourse"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>
  </v-app>
</template>
<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import "vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import ExamService from "@/core/services/exam/ExamService";
import ExamCourseService from "@/core/services/exam/course/ExamCourseService";

const levelService = new LevelService()
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
const examService = new ExamService();
const examCourseService = new ExamCourseService();

export default {
  name: "CreateOrUpdateExams",


  validations() {
    return{
      selected_exam_course: this.selected_exam_course_rule
    }

  },
  // practical_mark: {
  //   required: requiredUnless('hasPracticalCourse')
  // }, practical_pass_mark: {
  //   required: requiredUnless('hasPracticalCourse')
  // },
  computed: {
    hasPracticalCourse() {
      return false;
    },
    selected_exam_course_rule(){
      let rule = {}
      rule = {
        theory_mark: {required},
        theory_pass_mark: {required},
        start_date: {required},
        end_date: {required},
      }
      if(this.selected_exam_course.has_shiftwise_timing){
        rule.shift_type={required};
      }
      if(!this.selected_exam_course.has_shiftwise_timing){
        rule.start_time={required};
        rule.end_time={required};
      }
      if(this.selectedCourse && this.selectedCourse.has_practical){
        rule.practical_mark={required};
        rule.practical_pass_mark={required};
      }
      if(this.selected_exam_course.shift_type &&  this.selected_exam_course.shift_type.length > 0){
        if(this.selected_exam_course.shift_type.includes('morning')){
          rule.morning_start_time={required};
          rule.morning_end_time={required};
        }
        if(this.selected_exam_course.shift_type.includes('day')){
          rule.day_start_time={required};
          rule.day_end_time={required};
        }
        if(this.selected_exam_course.shift_type.includes('evening')){
          rule.evening_start_time={required};
          rule.evening_end_time={required};
        }

      }
      return rule;
    }

  },
  components: {},
  data() {
    return {
      selectedItem: [],

      exam: {
        title: null,
        type: null,
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        duration: null,
        duration_unit: null,
        exam_location: null,
        location_description: null,
        is_active: true
      },
      statuses: [
        {
          name:"Upcoming",
          value: "upcoming"
        },
        {
          name: "Completed",
          value: "completed"
        },
        {
          name: "Cancelled",
          value: "cancelled"
        },
        {
          name:"Postponed",
          value: "postponed"
        }
      ],
      selectedCourse: null,
      selected_exam_course: {
        exam_id: null,
        course_id: null,
        theory_mark: '',
        theory_pass_mark: '',
        practical_mark: '',
        has_practical: false,
        practical_pass_mark: '',
        position: null,
        start_date: null,
        start_time: null,
        morning_start_time: null,
        day_start_time: null,
        evening_start_time: null,

        end_date: null,
        end_time: null,
        morning_end_time: null,
        day_end_time: null,
        evening_end_time: null,
        status: 'upcoming',
        has_shiftwise_timing:false,
        shift_type:[],
      },
      date: new Date().toISOString().substr(0, 10),
      dialog: false,
      menu: false,
      edit: false,
      menu2: false,
      levels: [],
      programs: [],
      academicYears: [],
      courses: [],
      classes: [],
      exam_courses: [],
      types: [
        {
          text: 'Terminal',
          value: 'terminal',
        },
        {
          text: 'Class Test',
          value: 'class_test',
        },
        {
          text: 'Board',
          value: 'board',
        },
      ], locations: [
        {
          text: 'Online',
          value: 'online',
        },
        {
          text: 'In Premise',
          value: 'in-premise',
        },
        {
          text: 'Off Premise',
          value: 'off-premise',
        },
      ],
      durations_units: [
        'minutes', 'hours', 'days', 'months'
      ],
      academic_id: null,
      page: null,
      total: null,
      perPage: null,
      tab: null,
      course: {},
      shift_types:[
        {text:'Morning',value:'morning'},
        {text:'Day',value:'day'},
        {text:'Evening',value:'evening'},

      ],
    };
  },
  mounted() {
  },
  methods: {
    isValueInShiftType(shiftType) {
      if(this.selected_exam_course.shift_type && shiftType){
        return this.selected_exam_course.shift_type.includes(shiftType);
      }else{
        return null;
      }

    },
    handleShiftWiseTiming(){
      if(this.selected_exam_course.has_shiftwise_timing){
        this.selected_exam_course.end_time=null;
        this.selected_exam_course.start_time=null;
      }else{
        this.selected_exam_course.morning_start_time=null;
        this.selected_exam_course.morning_end_time=null;
        this.selected_exam_course.day_start_time=null;
        this.selected_exam_course.day_end_time=null;
        this.selected_exam_course.evening_end_time=null;
        this.selected_exam_course.evening_start_time=null;
        this.selected_exam_course.shift_type=[];
      }
    },
    handleShiftData(){

      if(this.selected_exam_course.shift_type.length > 0){
        if(!this.selected_exam_course.shift_type.includes('morning')){
          this.selected_exam_course.morning_start_time=null;
          this.selected_exam_course.morning_end_time=null;
        }
        if(!this.selected_exam_course.shift_type.includes('day')){
          this.selected_exam_course.day_start_time=null;
          this.selected_exam_course.day_end_time=null;
        }
        if(!this.selected_exam_course.shift_type.includes('evening')){
          this.selected_exam_course.evening_end_time=null;
          this.selected_exam_course.evening_start_time=null;
        }
      }else{
        this.selected_exam_course.morning_start_time=null;
        this.selected_exam_course.morning_end_time=null;
        this.selected_exam_course.day_start_time=null;
        this.selected_exam_course.day_end_time=null;
        this.selected_exam_course.evening_end_time=null;
        this.selected_exam_course.evening_start_time=null;
      }
    },
    showModal(exam = {}, course) {

      this.exam = exam
      if (this.exam.start_date) {
        this.selected_exam_course.start_date = this.exam.start_date;
      }
      if (this.exam.end_date) {
        this.selected_exam_course.end_date = this.exam.end_date;
      }
      this.selected_exam_course.status = 'upcoming';
      this.dialog = true
      if (course.id) {
        this.getAssignedCourses();
        this.edit = true
        this.selected_exam_course = course;
        if(this.selected_exam_course.shift_type){
          this.selected_exam_course.shift_type = this.selected_exam_course.shift_type.split(',');
        }else{
          this.selected_exam_course.shift_type=[];
        }
        this.selectedCourse = this.selected_exam_course.course;

      } else {
        this.getUnassignedCourses()
      }

    },
    hideModal() {
      this.teacher = [];
      this.selectedCourse = null;
      this.selected_exam_course = {
        exam_id: null,
        course_id: null,
        theory_mark: null,
        theory_pass_mark: null,
        practical_mark: null,
        practical_pass_mark: null,
        position: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        morning_start_time: null,
        evening_start_time: null,
        day_start_time: null,
        morning_end_time: null,
        day_end_time: null,
        evening_end_time: null,
        has_shiftwise_timing:false,
        shift_type:[],

        status:'upcoming'
      }
      this.edit = false;
      this.dialog = false;
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.dialog = !this.dialog;
    },

    getAssignedCourses() {
      examService.getAssignedCourses(this.exam.id).then(response => {
        this.courses = response.data;
      });
    },

    getUnassignedCourses() {
      examService.getUnAssignedCourses(this.exam.id).then(response => {
        this.courses = response.data;
      });
    },
    getClassExam() {
      examService.getClassExam(this.exam.program_id, this.exam.grade_id).then(response => {
        this.classes = response.data.class;
      });
    },
    getAcademicYears() {
      academicYearService.paginate().then(response => {
        this.academicYears = response.data.data;

      });
    },
    getGrades() {
      gradeLevelService.paginate().then(response => {
        this.gradesLevels = response.data.data;

      });
    },

    setCourse() {
      this.selected_exam_course.course_id = this.selectedCourse.course_id;
      this.selected_exam_course.theory_mark = this.selectedCourse.theory_mark && this.selectedCourse.theory_mark != "null" ? this.selectedCourse.theory_mark : ''
      this.selected_exam_course.theory_pass_mark = this.selectedCourse.theory_pass_mark && this.selectedCourse.theory_pass_mark != "null" ? this.selectedCourse.theory_pass_mark : '';
      this.selected_exam_course.practical_mark = this.selectedCourse.practical_mark && this.selectedCourse.practical_mark != "null" ? this.selectedCourse.practical_mark : '';
      this.selected_exam_course.practical_pass_mark = this.selectedCourse.practical_pass_mark && this.selectedCourse.practical_pass_mark != "null" ? this.selectedCourse.practical_pass_mark : '';
      this.selected_exam_course.position = this.selectedCourse.position;

    },

    saveOrUpdate() {

      this.$v.$touch();
      if (this.$v.selected_exam_course.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if(this.selected_exam_course.has_shiftwise_timing){
          if(this.selected_exam_course.shift_type && this.selected_exam_course.shift_type.length > 0){
            this.selected_exam_course.shift_type = this.selected_exam_course.shift_type.join(',');
          }else {
            this.selected_exam_course.shift_type=null;
          }
        }else{
          this.selected_exam_course.shift_type=null;
        }

        this.selected_exam_course.exam_id = this.exam.id;
        this.selected_exam_course.course_id = this.selectedCourse.id;
        if (this.edit) {
          examCourseService.update(this.exam.id, this.selected_exam_course.id, this.selected_exam_course).then((response) => {
            if (!response.data.status)
              this.$snotify.error(response.data.msg)
            else {
              this.$snotify.success("Information updated");
              this.getAssignedCourses();
              this.$emit('refresh');
              this.resetForm()
            }
          });
        } else {

          examCourseService.store(this.exam.id, this.selected_exam_course).then((response) => {
            if (!response.data.status)
              this.$snotify.error(response.data.msg)
            else {
              this.$snotify.success("Information updated");
              this.getAssignedCourses();
              this.$emit('refresh');
              this.resetForm()
            }
          });
        }
      }
    },

    deleteCourseExam(examId, courseId) {
      this.$confirm({
        message: `Are you sure you want delete?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            examCourseService.delete(examId, courseId).then((response) => {
              if (!response.data.status)
                this.$snotify.error(response.data.msg)
              else {
                this.$snotify.success("Information deleted");
                this.getAssignedCourses();
                this.$emit('refresh');
                this.resetForm()
              }
            });
          }
        }
      });

    },

    resetForm() {
      this.exam = {
        title: null,
        type: null,
        academic_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        duration: null,
        duration_unit: null,
        exam_location: null,
        location_description: null,
        is_active: true
      }
      this.selected_exam_course = {
        exam_id: null,
        course_id: null,
        theory_mark: null,
        theory_pass_mark: null,
        practical_mark: null,
        practical_pass_mark: null,
        position: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        status:'upcoming'
      };
      this.exam_courses = [];
      this.$v.$reset();
      this.hideModal()

    }
  }
};
</script>
