<template>
  <v-app>
    <div class="" style="height: 100vh">
      <table class="table">
        <thead>
        <tr class="px-3">
          <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">	Name</th>
          <th class="px-3 font-size-sm">Th.<br/> FM
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  color="info"
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
              >
                fa fa-info-circle
              </v-icon>
            </template>
            <span>Theory full mark</span>
          </v-tooltip>
          </th>
          <th class="px-3">Th.<br/> PM
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="info"
                    dark
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                  fa fa-info-circle
                </v-icon>
              </template>
              <span>Theory pass mark</span>
            </v-tooltip>
          </th>
          <th class="px-3">Pr.<br/> FM
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="info"
                    dark
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                  fa fa-info-circle
                </v-icon>
              </template>
              <span>Practical full mark</span>
            </v-tooltip></th>
          <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Pr. <br/>PM
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="info"
                    dark
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                  fa fa-info-circle
                </v-icon>
              </template>
              <span>Practical pass mark</span>
            </v-tooltip></th>
          <th class="px-3">Status</th>
          <th class="pr-3 text-center"></th>
        </tr>
        </thead>
        <tr  v-for="(item, index) of courses" :key="index">
          <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
           <span  class=" font-weight-bolder text-hover-primary mb-1">
             <a @click="summaryPage(exam,item)">{{ getCourseTitle(item.course) }}</a>
           </span>
          </td>
          <td class="px-3">
            {{item.theory_mark}}
          </td>
          <td class="px-3">
            {{item.theory_pass_mark}}
          </td>
          <td class="px-3">
            {{item.practical_mark}}
          </td>
          <td class="px-3">
            {{item.practical_pass_mark}}
          </td>
          <td>
            <div v-html="getBadge(item.status_text)"></div>
          </td>
          <td class="pr-0 text-center">
            <template>
              <b-dropdown
                  size="sm"
                  variant="link"
                  toggle-class="custom-v-dropdown"
                  no-caret
                  right
                  text="Actions"

              >
                <template v-slot:button-content>
                  <a href="#" class="" data-toggle="dropdown">
                    <!--              <span class="svg-icon svg-icon-success svg-icon-2x">-->
                    <!--begin::Svg Icon-->
                    <i class="flaticon-more-1"></i>
                    <!--end::Svg Icon-->
                    <!--              </span>-->
                  </a>
                </template>
                <div class="navi navi-hover">



                  <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('exam', 'marks-entry') && exam.is_exam_confirmed">
                    <router-link
                        :to="{name:'results-view-all-courses-students',params:{slug:exam.slug, 'courseId':item.course_id}}"

                        class="navi-link">
                <span class="navi-icon">
                  <i class="fa fa-marker"></i>
                </span>

                      <span class="navi-text">Result entry</span>
                    </router-link>
                  </b-dropdown-text>


                  <b-dropdown-text tag="div" class="navi-item"
                                   v-if="enableAdditionalAssessment(item.id) && checkIsAccessible('exam', 'assessment-marks-entry') && item.status=='completed'">
                    <router-link
                        :to="{name:'results-view-courses-assessments',params:{slug:exam.slug, 'courseId':item.id}}"

                        class="navi-link">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-bong"></i>
                                                                    </span>
                      <span class="navi-text">Assessment marks entry</span>
                    </router-link>
                  </b-dropdown-text>

                  <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('exam', 'import-result')  && exam.is_exam_confirmed">
                    <a href="#" @click="openImportResult(exam.id,item.course)" class="navi-link">
                                                                   <span class="navi-icon">
                                                                      <i class="fa fa-file-export"></i>
                                                                    </span>
                      <span class="navi-text"> Import course result (excel)</span>

                    </a>
                  </b-dropdown-text>

                  <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('exam', 'download-excel-format')">
                    <a href="#" @click="downloadImportCsv(exam.id,item.course_id)" class="navi-link">
                                                                   <span class="navi-icon">
                                                                      <i class="fa fa-file-excel"></i>
                                                                    </span>
                      <span class="navi-text"> Download result entry (excel)</span>

                    </a>
                  </b-dropdown-text>
                  <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('exam', 'edit')">
                    <a class="navi-link" @click="manageAdditionalAssessment(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-cog"></i>
                                  </span>
                      <span class="navi-text">Additional assessment</span>
                    </a>
                  </b-dropdown-text>


                  <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('exam', 'marks-entry')">
                    <a href="#" @click="showModal(exam,item)" class="navi-link">
                                                                   <span class="navi-icon">
                                                                      <i class="fa fa-edit"></i>
                                                                    </span>
                      <span class="navi-text"> Edit</span>

                    </a>
                  </b-dropdown-text>

                  <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('exam-course', 'delete')  && !exam.is_exam_confirmed">
                    <a href="#" @click="deleteCourseExam(exam.id,item.id)" class="navi-link">
                                                                   <span class="navi-icon">
                                                                      <i class="fa fa-trash"></i>
                                                                    </span>
                      <span class="navi-text"> Delete</span>

                    </a>
                  </b-dropdown-text>

                </div>
                <!--end::Navigation-->


                <!--end::Navigation-->
              </b-dropdown>
            </template>
          </td>

        </tr>
        <tr v-show="courses.length==0">
          <td colspan="7" class="text-center">
            <strong>No data available to display.</strong>
          </td>
        </tr>
      </table>
    </div>
    <v-dialog
        v-model="dialog"
        width="500"
        title="Manage Result"
    >
      <v-card>
        <v-card-title>
          {{import_modal_title}}
        </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <p>Please make sure to check your file is in correct format provided by the system.</p>
              <v-file-input outlined dense v-model="excel_file"
                            accept=".xlsx, .xls, .csv"
                            label="Select file with results"
                            :error="$v.excel_file.$error"
              ></v-file-input>
              <p class="mt-2">
                <i class="fa fa-file-excel"></i> You can download result entry excel file for this subject <a href="#" @click="downloadImportCsv(exam.id,import_modal_course_id)" class="navi-link">
                <span class="navi-text"> here</span></a>
              </p>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn :loading="isLoading"
                 class="text-white ml-2 btn btn-primary"
                 @click="importResult"
          >
            Upload & import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <assign-courses ref="assignCourse" @refresh="getAssignedCourses"></assign-courses>
  </v-app>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import ExamService from "@/core/services/exam/ExamService";
import AdditionalAssessmentService from "@/core/services/additionalAssessment/AdditionalAssessmentService";
import AssignCourses from "./../AssignCourses";
import ExamCourseService from "@/core/services/exam/course/ExamCourseService";

const examCourseService = new ExamCourseService();
const additionalAssessment = new AdditionalAssessmentService();
const examService = new ExamService();

import {API_URL} from "@/core/config";

export default {
  name: "courses",
  components: {
    AssignCourses
  },
  validations:{
    excel_file:{required}
  },

  props: ['courses', 'exam'],
  data() {
    return {
      headers: [
        {text: 'Name', value: 'course.title'},
        {text: 'Th. Full Mark', value: 'theory_mark'},
        {text: 'Th. Pass Mark', value: 'theory_pass_mark'},
        {text: 'Pr. Mark', value: 'practical_mark'},
        {text: 'Pr. Pass Mark', value: 'practical_pass_mark'},
        {text: 'c', value: 'status_text'},
        {text: '', value: 'action'},
      ],
      excel_file: null,
      loading: true,
      dialog: false,
      isLoading: false,
      import_modal_title:'Course result import',
      import_modal_course_id:null,
      additional_assessment: []
    }
  },
  mounted() {
    // if (this.courses && this.courses.length > 0) {
    //   this.checkForAdditionalAssessment()
    // }
  },
  methods: {
    checkForAdditionalAssessment() {
      this.loading = true
      let courses = []
      courses = this.courses.map(ele => {
        return ele.id
      })
      let data = {
        academic_year_id: this.exam.academic_year_id,
        course_ids: courses
      }
      additionalAssessment.checkCourseAdditionalAssessment(data).then(response => {
        this.additional_assessment = response.data.additional_assessment
        this.loading = false
      }).catch(error => {
        this.loading = false
        this.$snotify.error('Something went wrong')
      }).finally(() => {
        this.loading = false
      })
    },
    openImportResult(id,item) {
      this.import_modal_title = 'Import result for '+item.title;
      this.import_modal_course_id = item.id;
      this.dialog = true
      this.examId = id;


    },
    importResult() {
      this.$v.excel_file.$touch();
      if (this.$v.excel_file.$error) {
        setTimeout(() => {
          this.$v.excel_file.$reset();
        }, 3000);
      } else {
        this.isLoading = true;
        let fd = new FormData();
        fd.append("file", this.excel_file);
        examService.importCourseResult(this.examId, fd).then(response => {
          this.isLoading = false
          this.excel_file = null;
          this.$snotify.success("CSV imported and processed")
          this.examId = null;
          this.dialog = false
          this.getExams();
          this.getAssignedCourses()
        });
      }
    },
    downloadImportCsv(examId, courseId) {
      let url = "";
      let data = {
        exam_id: examId,
        course_id: courseId
      }
      var queryString = Object.keys(data)
          .map(key => {
            return (
                encodeURIComponent(key) +
                "=" +
                encodeURIComponent(data[key])
            );
          })
          .join("&");
      if (queryString) {
        url = url + "?" + queryString;
      }

      url = API_URL + 'user/download/import/course/result' + url;

      window.open(
          url,
          "_blank"
      );
    }, enableAdditionalAssessment(id) {
      if (this.additional_assessment && this.additional_assessment.length > 0)
        if (this.additional_assessment.includes(id))
          return true
      return false
    },

    showModal(exam = {}, course = {}) {
      this.$refs["assignCourse"].showModal(exam, course);
    },

    getAssignedCourses() {
      this.$emit('refresh');
    }
    ,
    deleteCourseExam(examId, courseId) {
      this.$refs["assignCourse"].deleteCourseExam(examId, courseId);
    },
    recalculateCourseResult(examId, courseId) {
      this.$confirm({
        message: `Are you sure you want to schedule to generate result?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            examCourseService.recalculateCourseResult(examId, courseId).then(response => {
              this.displayMessage("success")
            }).catch(error => {
              this.displayMessage('error')
            })
          }
        }
      });
    },
    getCourseTitle(course) {
      let title = course.title
      if (course.is_internal) {
        title = title + "(IN)";
      }
      return title +"-"+ course.subject_code
    },
    summaryPage(exam,item){

      this.$router.push({
        name:'results-view-all-courses-students',
        params:{slug:exam.slug, 'courseId':item.course_id}
      });
    },
    manageAdditionalAssessment(item) {
      this.$router.push({name: 'course-assessment', params: {id: item.course_id}})
    }
  },
}
</script>