<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Exam timetable
                </h4>

              </div>
              <div class="breadcrumb-right">
                <v-btn   v-if="checkIsAccessible('exam', 'download-time-table')"
                       @click="viewTimetable()"
                       class="mt-4 btn btn-primary  mr-1"
                       dark>
                  <i class="fa fa-file-pdf"></i>
                  Print Timetable
                </v-btn>

              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="">
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Name</th>
                    <th class="px-3">Start </th>
                    <th class="px-3">End</th>
                    <th class="px-3">Status</th>
                    <th class="pr-3 text-center">Attendance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="courses.length > 0" v-for="item in courses" :key="item.id" >
                      <td style="max-width: 270px !important; white-space: pre-wrap;">
                        <span  class=" font-weight-bolder text-hover-primary mb-1">
                          {{ item.course.title }} | {{item.course.subject_code}}
                      </span>
                      </td>
                    <td>
                      <span class="font-weight-medium">
                       {{ item.formatted_start_date }} {{ item.formatted_start_time }}
                      </span>
                    </td>
                    <td>
                      <span class="font-weight-medium">
                        {{item.formatted_end_date}} {{ item.formatted_end_time }}
                      </span>
                    </td>
                    <td>
                      <div v-html="getBadge(item.status_text)"></div>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="openExamAttendance(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-clock"></i>
                                  </span>
                                <span class="navi-text">Manage</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="exportExamAttendance(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-file-excel"></i>
                                  </span>
                                <span class="navi-text">Download CSV</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="previewExamAttendance(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-print"></i>
                                  </span>
                                <span class="navi-text">Print preview </span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-show="courses.length==0">
                    <td colspan="5" class="text-center">
                      <strong>No data available to display.</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-row>
      <v-col>

      </v-col>
    </v-row>
<!--    <v-data-table
        :headers="headers"
        :items="courses"
        :items-per-page="15"
        class="elevation-1"
    >
      <template v-slot:item.start_date_time="{ item,index }">
        <span>{{ item.formatted_start_date }} </span>
      </template>
      <template v-slot:item.status_text="{ item,index }">
        <div v-html="getBadge(item.status_text)"></div>
      </template> <template v-slot:item.end_date_time="{ item,index }">
        <span>{{ item.formatted_start_date }} </span>
      </template>
    </v-data-table>-->
    <vue-html2pdf
        :enable-download="true"
        :float-layout="true"
        :manual-pagination="false"
        :paginate-elements-by-height="1400"
        :pdf-quality="2"
        :preview-modal="false"
        :show-layout="false"
        @hasGenerated="hasGenerated($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @progress="onProgress($event)"
        filename="tes"

        pdf-content-width="800px"
        pdf-format="a4"
        pdf-orientation="landscape"
        ref="html2Pdf"
    >
      <section slot="pdf-content">
        <v-card>
          <!--          <v-card-title class="d-flex flex justify-content-center">-->
          <!--            Time Table-->

          <!--          </v-card-title>-->
          <!--          <v-subtitle  class="d-flex flex justify-content-center">-->
          <!--            {{`${academic_class_room.title} - ${academic_class_room.shift}`}}-->
          <!--          </v-subtitle>-->
          <!--          <v-subtitle  class="d-flex flex justify-content-center">-->
          <!--            {{`${academic_class_room.program.title} - ${academic_class_room.grade_title}`}}-->
          <!--          </v-subtitle>-->
          <v-card-text>
            <div class="table table-stripe">
              <!--                    <div class="title text-left mb-10">-->
              <!--                        Timetable for {{academicClassTimetable.title}}-->
              <!--                    </div>-->
              <table class="table  table-stripe">

                <tbody class="">

                <tr v-for="(item,index) in courses" :key="index">
                  <td> {{ item.course.title }}</td>
                  <td> {{ item.course.subject_code }}</td>
                  <td><span>{{ item.start_date }} {{ item.start_time || '' }}</span></td>
                  <td><span>{{ item.end_date }} {{ item.end_time || '' }}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </v-card-text>
        </v-card>

      </section>
    </vue-html2pdf>

  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import "vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import ExamService from "@/core/services/exam/ExamService";
import {API_URL} from "@/core/config";


const levelService = new LevelService()
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
const examService = new ExamService();
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: "CoursesTimeTable",
  props: ['courses', 'exam'],
  data() {
    return {
      headers: [
        {text: 'Name', value: 'course.title'},
        {text: 'Subject code', value: 'course.subject_code'},
        {text: 'Start date & time', value: 'start_date_time'},
        {text: 'End date & time', value: 'end_date_time'},
        {text: 'Status', value: 'status_text'},
      ],

      selectedItem: [],

      date: new Date().toISOString().substr(0, 10),
      dialog: false,
      start_date_menu: [],
      end_date_menu: [],
      edit: false,
      menu: [],
      menu2: [],
      levels: [],
      programs: [],
      academicYears: [],
      classes: [],
      items: ["Upcoming", "Completed", "Cancelled", "Postponed"],
      durations_units: [
        'minutes', 'hours', 'days', 'months'
      ],
      academic_id: null,
      page: null,
      total: null,
      perPage: null,
      tab: null,
    };
  },
  components: {VueHtml2pdf},
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    viewTimetable(){
      window.open(
        API_URL + "user/exam/" + this.examId + '/timetable/preview',
        "_blank"
      );
    },  
    resetForm() {
      this.exam = {
        title: null,
        type: null,
        academic_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        duration: null,
        duration_unit: null,
        exam_location: null,
        location_description: null,
        is_active: true
      }
      this.exam_courses = [];
    },
    openExamAttendance(item){
      this.$router.push(
          {
            name: 'exam-attendance-courses-students',
            params: {slug:this.exam.slug, 'courseId':item.course_id}
          }
      )
    },
    exportExamAttendance(item){
      examService
          .exportExamAttendance(this.exam.id,item.course_id)
          .then(response => {

          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
    previewExamAttendance(item){
      examService
          .previewExamAttendance(this.exam.id,item.course_id)
          .then(response => {

          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
  },
  computed: {
    examId(){
      return this.$route.params.exam_id;
    }
  },
};
</script>
