<template>
  <v-app>

    <v-dialog
        v-model="dialog"
        width="1100"
        title="Courses Timetable"
    >
      <v-card>
        <v-card-title>
          Set course timetable
        </v-card-title>
        <v-card-text v-if="courses.length">
          <div class="row "  v-for="(course, i) in courses"
               :key="i">
            <div class="col-md-2">
              <strong v-if="course.course">{{course.course.title }}</strong>
            </div>
            <div class="col-md-2">
              <label>Exam Date</label>
              <v-menu
                      v-model="menu[courses.indexOf(course)]"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                          outlined
                          dense
                          v-model="course.start_date"
                          label="Exam Start Date"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                        v-model="course.start_date"
                        @input="menu[courses.indexOf(course)] = false"
                ></v-date-picker>
              </v-menu>
            </div>

            <div class="col-md-2">
              <label>Exam Start Time</label>
              <v-text-field  outlined
                             dense v-model="course.start_time" type="time"></v-text-field>
            </div>

            <div class="col-md-2">
              <label>Exam Date</label>
              <v-menu
                      v-model="menu2[courses.indexOf(course)]"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                          outlined
                          dense
                          v-model="course.end_date"
                          label="Exam End Date"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                        v-model="course.end_date"
                        @input="menu2[courses.indexOf(course)] = false"
                ></v-date-picker>
              </v-menu>

            </div>

            <div class="col-md-2">
              <label>Exam End Time</label>
              <v-text-field dense type="time"
                            v-model="course.end_time" outlined></v-text-field>
            </div>
            <div class="col-md-2">
              <label>Status</label>
              <v-select dense outlined v-model="course.status"  :items="items">

              </v-select>
            </div>
          </div>
        </v-card-text>

        <v-card-text v-else>
          <div class="col-md-12 text-center">
            <h6 class="text-info">Courses aren't assigned yet. Please assign courses to this exam.</h6>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
                  class="btn btn-standard"
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
              v-if="courses.length"
              class="btn btn-primary text-white"
              text
              @click="saveTimeTable()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>
  </v-app>
</template>
<script>
import vMultiselectListbox from 'vue-multiselect-listbox'
import {required} from "vuelidate/lib/validators";
import "vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import ExamService from "@/core/services/exam/ExamService";

const levelService = new LevelService()
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
const examService = new ExamService();

export default {
  name: "CoursesTimeTable",
  validations: {
    exam: {
      title: {required},
      type: {required},
      academic_year_id: {required},
      level_id: {required},
      program_id: {required},
      grade_id: {required},
      start_date: {required},
      end_date: {required},
      duration: {required},
      duration_unit: {required},
      exam_location: {required},
    }
  },
  components: {
    // vMultiselectListbox
  },
  data() {
    return {
      selectedItem: [],

      exam: {
        title: null,
        type: null,
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        duration: null,
        duration_unit: null,
        exam_location: null,
        location_description: null,
        is_active: true
      },
      date: new Date().toISOString().substr(0, 10),
      dialog: false,
      start_date_menu: [],
      end_date_menu: [],
      edit: false,
      menu: [],
      menu2: [],
      levels: [],
      programs: [],
      academicYears: [],
      courses: [],
      classes: [],



      items:["Upcoming","Completed","Cancelled","Postponed"],
      exam_courses: [],
      types: [
        {
          text: 'Terminal',
          value: 'terminal',
        },
        {
          text: 'Class Test',
          value: 'class_test',
        },
        {
          text: 'Board',
          value: 'board',
        },
      ], locations: [
        {
          text: 'Online',
          value: 'online',
        },
        {
          text: 'In Premise',
          value: 'in-premise',
        },
        {
          text: 'Off Premise',
          value: 'off-premise',
        },
      ],
      durations_units: [
        'minutes', 'hours', 'days', 'months'
      ],
      academic_id: null,
      page: null,
      total: null,
      perPage: null,
      tab: null,
    };
  },
  mounted() {
    this.getLevels();
    this.getAcademicYears()
    this.getGrades()
  },
  methods: {
    showModal(exam = {}) {
      if (exam.id) {

        this.edit = true
        this.dialog = true
        this.exam = exam
        this.getAssignedCourses()
      }
    },
    hideModal() {
      this.teacher = []
      this.dialog = false;
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.dialog = !this.dialog;
    },

    getAssignedCourses() {

      examService.getAssignedPivotCourses(this.exam.id).then(response => {
        this.courses = response.data.courses;


      });
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;

      });
    },
    getCourses() {
      examService.getAssignedCourses(this.exam.id).then(response => {
        this.courses = response.data.courses;
      });
    },
    getClassExam() {
      examService.getClassExam(this.exam.program_id, this.exam.grade_id).then(response => {
        this.classes = response.data.class;
      });
    },
    getAcademicYears() {
      academicYearService.paginate().then(response => {
        this.academicYears = response.data.data;

      });
    },
    getGrades() {
      gradeLevelService.paginate().then(response => {
        this.gradesLevels = response.data.data;

      });
    },

    saveTimeTable() {
      this.exam_courses = [];
      this.courses.forEach((item) => {
        this.exam_courses.push({
          exam_id: this.exam.id,
          course_id: item.course.id,
          start_date: item.start_date,
          start_time: item.start_time,
          end_date: item.end_date,
          end_time: item.end_time,
          status: item.status,
        })
      });


      examService.saveTimetable(this.exam.id, this.exam_courses).then((response) => {
        this.resetForm()
        this.$snotify.success("Information updated");
      });

    },

    resetForm() {
      this.exam = {
        title: null,
        type: null,
        academic_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        duration: null,
        duration_unit: null,
        exam_location: null,
        location_description: null,
        is_active: true
      }
      this.exam_courses = [];
      this.hideModal()

    }
  }
};
</script>
