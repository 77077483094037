<template>
  <v-app>
    <v-dialog
        v-model="dialog"
        max-width="900"
        persistent
    >
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            Mark sheet settings
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="resetForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <div class="row">

              <div class="col-12">
                <result-certificate :exam="exam" ref="mark_sheet"
                                    @update-exam="updateExam"></result-certificate>
              </div>

            </div>
          </v-container>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="primary"
              @click="updateExam"
              dark
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-app>

</template>
<script>
import {required} from "vuelidate/lib/validators";
import "vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import ExamService from "@/core/services/exam/ExamService";
import GradePointService from "@/core/services/level/program/grade-point/GradePointService";
import ResultCertificate from "./summary/ResultCertificate";

const levelService = new LevelService()
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
const examService = new ExamService();
const pointService = new GradePointService();

export default {
  name: "CreateOrUpdateExams",
  validations: {
    exam: {
      title: {required},
      exam_year: {required},
      type: {required},
      academic_year_id: {required},
      level_id: {required},
      program_id: {required},
      grade_id: {required},
      start_date: {required},
      end_date: {required},
      exam_location: {required},
    }
  },
  components: {
    ResultCertificate
  },
  data() {
    return {
      search: {
        academic_year: "",
        program_id: "",
        grade_id: "",
      },
      exam: {
        title: null,
        exam_year: null,
        type: 'terminal',
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        /*  duration: null,
          duration_unit: 'hours',*/
        has_additional_assessment: false,
        exam_location: 'in-premise',
        location_description: null,
        is_active: true,
        isBusy: false,
        is_classes_exam: false,
        applicable_classes: null
      },
      points: [],
      additional_assessments: [],
      overall_grading_points: [],
      classes: [],
      previousExams: [],
      selected: [],
      date: new Date().toISOString().substr(0, 10),
      dialog: false,
      isBusy: false,
      result_publish_date: false,
      menu: false,
      edit: false,
      menu2: false,
      levels: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      types: [
        {
          text: 'Terminal',
          value: 'terminal',
        },
        {
          text: 'Class test',
          value: 'class_test',
        },
        {
          text: 'Board',
          value: 'board',
        },
      ],
      locations: [
        {
          text: 'Online',
          value: 'online',
        },
        {
          text: 'In premise',
          value: 'in-premise',
        },
        {
          text: 'Off premise',
          value: 'off-premise',
        },
      ],
      durations_units: [
        'minutes', 'hours', 'days', 'months'
      ],
      academic_id: null,
      page: null,
      total: null,
      perPage: null,
      tab: null,
    };
  },
  mounted() {
    this.getLevels();
    this.getAcademicYears();
    this.getCourseGradePoints();
    this.getOverallGradePoints();
  },
  methods: {
    getExam(){
      this.$emit('refresh');
    },

    getCourseGradePoints() {
      pointService.paginate({is_active: 1, grade_point_type: 'course'}).then(response => {
        let points = response.data.data
        if (points && points.length > 0) {
          points.forEach(ele => {
            ele.display_title = `${ele.title} (${ele.type})`
            this.points.push(ele)
          })
        }
      })
    },

    getOverallGradePoints() {
      pointService.paginate({is_active: 1, grade_point_type: 'overall'}).then(response => {
        let points = response.data.data
        if (points && points.length > 0) {
          points.forEach(ele => {
            ele.display_title = `${ele.title} (${ele.type})`
            this.overall_grading_points.push(ele)
          })
        }
      })
    },

    showModal(exam = {}, academic_class = {}) {
      this.buildSelected();
      if (exam.id) {
        this.edit = true
        this.exam = exam
        this.getPrograms()
        this.getGrades();
        this.getSections()
        this.buildSelected();
        this.getPreviousExams();

      } else if (academic_class.id) {
        this.exam.level_id = academic_class.level_id
        this.exam.program_id = academic_class.program_id
        this.exam.academic_year_id = academic_class.academic_year_id
        this.exam.grade_id = academic_class.grade_id
        this.selected.push(academic_class.id)
        this.getPrograms()
        this.getGrades();
        this.getSections()

        this.edit = false
      } else {
        this.edit = false;
        this.resetForm();
      }
      this.dialog = true
      // this.$refs["my-modal"].show();
    },

    hideModal() {
      this.teacher = []
      this.dialog = false;
    },

    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;

      });
    },

    buildSelected() {
      let ids = this.exam.applicable_classes;

      if (ids && ids.length > 0) {
        ids = ids.split(",");
        ids.forEach(ele => {
          this.selected.push(parseInt(ele));
        });
      }

    },
    getPreviousExams() {
      this.search.academic_year = this.exam.academic_year_id
      this.search.program_id = this.exam.program_id
      this.search.grade_id = this.exam.grade_id
      // console.log(this.search)
      examService.paginate(this.search).then(response => {
        this.previousExams = response.data.data;
      }).catch((err) => {
        // console.log(err)
      }).finally(() => {
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.exam.level_id).then(response => {
        this.programs = response.data;
        if (this.exam.program_id)
          this.getProgram()
      });
    },
    getProgram() {
      programService.show(this.exam.program_id).then(response => {
        if (response.data.program && response.data.program.additional_assessments)
          this.additional_assessments = response.data.program.additional_assessments
      });
    },
    getAcademicYears() {
      academicYearService.paginate().then(response => {
        this.academicYears = response.data.data;

      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.exam.program_id).then(response => {
        this.gradesLevels = response.data;
      });
    },

    getSections() {
      examService.getClassExam(this.exam.program_id, this.exam.grade_id).then(response => {
        this.classes = response.data.class;
      });
    },

    updateExam() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            examService.update(this.exam.id, this.exam).then(response => {
              if(response.data.status =="OK") {
                this.$snotify.success("Information updated");
                this.getExam();
                this.hideModal();
                this.$refs['mark_sheet'].resetForm();
                this.$emit('refresh');
              }
            });
          }
        }
      });
    },

    resetForm() {
      this.selected = [];
      this.$v.$reset()
      this.hideModal()
      this.exam = {
        title: null,
        type: null,
        academic_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        has_additional_assessment: false,
        exam_location: null,
        location_description: null,
        is_classes_exam: false,
        applicable_classes: null,
        is_active: true
      }

    }
  }
};
</script>
<style>
.v-application--wrap {
  min-height: 0 !important;
}
</style>
