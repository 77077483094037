<template>
  <v-app>
    <v-row class="m-1" v-if="isBusy">
      <v-col cols="2">
        <v-skeleton-loader v-bind="attrs" type="list-item, list-item-two-line, list-item-three-line"></v-skeleton-loader>
        <v-skeleton-loader v-bind="attrs" type="list-item, list-item-two-line, list-item-three-line"></v-skeleton-loader>
      </v-col>
      <v-col cols="10">
        <v-skeleton-loader  v-bind="attrs" type="article, article"></v-skeleton-loader>
        <v-skeleton-loader  v-bind="attrs" type="article, article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="!isBusy">
      <v-col cols="12" md="3" lg="3" xl="2">
        <v-card flat v-if="exam">
          <v-card-title class="justify-center">
            Exam summary
          </v-card-title>
          <v-card-text>
            <div class="text-center" v-if="exam">
              <div class="d-flex align-items-center justify-content-between mb-2">
                <strong class="heading">{{ exam.title }}</strong>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2"
                   v-if="exam && exam.unique_no">
                <span class="font-weight-bold mr-2">Unique No.</span>
                <strong class="heading">{{ exam.unique_no }}</strong>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Program</span>
                <strong class="heading">{{ exam.program_title }}</strong>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Semester/year</span>
                <strong class="heading">{{ exam.semester_title }}</strong>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2"
                   v-if="exam && exam.academic_year_title">
                <span class="font-weight-bold mr-2">Batch</span>
                <strong class="heading">{{ exam.academic_year_title }}</strong>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Type</span>
                <strong class="heading">{{ exam.type }}</strong>
              </div>

              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Start date</span>
                <strong class="heading">{{ exam.formatted_start_date }}</strong>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">End date</span>
                <strong class="heading">{{ exam.formatted_end_date }}</strong>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Total assigned students</span>
                <strong class="heading">{{ exam.total_student || 0 }}</strong>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Total attendance days</span>
                <strong class="heading">{{ exam.total_attendance_days || 0 }}</strong>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Result status</span>
                </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Exam status</span>
               </div>
            </div>
            <v-tabs class="menu-parent" vertical v-model="model">
              <v-tab>
                <v-icon left>
                  fas fa-bar-chart
                </v-icon>
                Summary
              </v-tab>
              <v-tab>
                <v-icon left>
                  fas fa-user-tie
                </v-icon>
                Courses & results
              </v-tab>
              <v-tab>
                <v-icon left>
                  fas fa-calendar-check
                </v-icon>
                Timetable
              </v-tab>
              <v-tab>
                <v-icon left>
                  fas fa-user-graduate
                </v-icon>
                Students
              </v-tab>

              <v-tab>
                <v-icon left>
                  fa fa-bell
                </v-icon>
                Publish & notification
              </v-tab>
              <v-tab>
                <v-icon left>
                  fa fa-cog
                </v-icon>
                Settings
              </v-tab>

            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12"  md="9" lg="9" xl="10">
        <v-tabs-items v-model="model">
          <v-tab-item>
            <exam-dashboard :exam="exam" v-if="exam"></exam-dashboard>
          </v-tab-item>
          <v-tab-item>
            <div class="row">
              <div class="col-12">
                <v-card flat>
                  <div class="card-header">
                    <div class="card-title pt-3 px-3 d-flex justify-content-between">
                      <div class="breadcrumb-left">
                        <h4>Courses</h4>
                      </div>
                      <div class="breadcrumb-right" >
                        <div class="card-toolbar">
                          <b-dropdown
                              size="sm"
                              variant="primary"
                              toggle-class="btn btn-transparent-white btn-sm font-weight-bolder px-5"
                              text="Result"
                          >
                            <!--begin::Navigation-->
                            <div class="quick-actions navi navi-hover min-w-md-250px">
                            <!--begin::Navigation-->
                              <b-dropdown-text tag="div" class="navi-item" v-if="exam &&  exam.is_exam_confirmed" >
                                <router-link  :to="{ name: 'results-view-all-courses-summary-students' , params: {examId: exam.id}}"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                                  <span class="navi-text">Enter marking</span>
                                </router-link>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="exam &&  exam.is_exam_confirmed" >
                                <a @click.prevent="calculateTotal(exam)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-sync-alt"></i>
                                </span>
                                  <span class="navi-text">{{exam.is_total_calculated ? 'Recalculate' : 'Calculate'}} total</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="exam &&  exam.is_exam_confirmed" >
                                <a @click.prevent="calculateAllCourseResult(exam)"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-sync"></i>
                                </span>
                                  <span class="navi-text">Recalculate course results</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="exam &&  exam.is_exam_confirmed" >
                                <a @click="downloadLedger(exam.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-excel"></i>
                                </span>
                                  <span class="navi-text">Download ledger</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item" v-if="exam && exam.total_attendance_days" >
                                <a @click="exportAttendanceLedger(exam.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-excel"></i>
                                </span>
                                  <span class="navi-text">Download attendance (excel)</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item" v-if="exam && exam.total_attendance_days" >
                                <a @click="importAttendanceLedgerDialog()" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-excel"></i>
                                </span>
                                  <span class="navi-text">Import attendance (excel)</span>
                                </a>
                              </b-dropdown-text>

                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                          <v-btn v-if="checkIsAccessible('exam', 'assign-course')"  @click.prevent="assignCourse(exam)"  class=" btn-secondary ml-5" style="color: #000">
                            <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                            Assign course
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-card-text>
                    <courses ref="course" v-if="exam_courses" :courses="exam_courses" :exam="exam"
                             @refresh="getAssignedCourses"></courses>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <timetable :courses="exam_courses" :exam="exam"></timetable>

          </v-tab-item>
          <v-tab-item>
            <student :exam="exam"></student>
          </v-tab-item>

          <v-tab-item>
            <v-card flat>
              <v-card-title>
                <div class="col-md-12 text-left">
                  <h4>Publish & notification</h4>
                </div>
              </v-card-title>
              <v-card-text>
                <div class="row">
                  <div v-if="exam && exam.is_exam_confirmed" class="col-md-12 mb-5 border border-light-grey"
                       style="min-height: 150px">
                    <h6>Publish result and notify students</h6>
                    <p>Click on this button to publish result. This action will also notify students
                      on result publication on their email address.</p>
                    <a v-if="exam && exam.result_status != 'published'" href="#"
                       class="btn btn-dark ml-2 mt-2" @click.prevent="markAs(exam.id, 'published')">
                                          <span class="navi-icon">
                                            <i class="fa fa-user-check"></i>
                                          </span>
                      <span class="navi-text">Mark as result published</span>
                    </a>
                    <a v-if="exam &&  exam.result_status == 'published'" href="#"
                       class="btn btn-warning ml-2 mt-2 text-white"
                       @click.prevent="markAs(exam.id, 'unpublished')">
                                      <span class="navi-icon">
                                        <i class="fa fa-user-check"></i>
                                      </span>
                      <span class="navi-text"> Mark as result not published</span>
                    </a>
<!--                    <v-btn :loading="load_notify" @click="notifyStudents(exam.id)" class="btn btn-secondary ml-2 mt-2" depressed >-->
<!--                                              <span class="navi-icon">-->
<!--                                                <i class="fa fa-bell"></i></span>-->
<!--                      <span class="navi-text">Notify Students via Email on Result Publish</span>-->
<!--                    </v-btn>-->
                  </div>
                  <div v-if="exam && exam.is_exam_confirmed" class="col-md-12 mb-5 border border-light-grey"
                       style="min-height: 150px">
                    <h6>Notification</h6>
                    <p>Send email to students</p>

                    <button v-if="exam &&  exam.is_exam_confirmed"
                            @click="sendExamResultConfirmedEmail(exam)"
                            class="btn btn-primary ml-2 mt-2">
                        <span class="navi-icon">
                        <i class="fa fa-bell"></i>
                      </span>
                      <span class="navi-text" v-if="exam">Send exam confirmed email</span>
                    </button>
                    <button v-if="exam &&  exam.result_status == 'published'"
                            @click="sendExamResultPublishedEmail(exam)"
                            class="btn btn-primary ml-2 mt-2">
                        <span class="navi-icon">
                        <i class="fa fa-bell"></i>
                      </span>
                      <span class="navi-text" v-if="exam">Send result published email</span>
                    </button>
                  </div>
                  <div v-if="exam && !exam.is_exam_confirmed" class="col-md-12 mt-10 text-center" style="min-height: 150px">
                    <h2 >Exam has not been confirmed.</h2>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-title>
                <div class="col-md-6 text-left">
                  <h4>Exam setting</h4>
                </div>
                <div class="col-md-6 text-right mb-3">
                  <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover min-w-md-250px" >
                      <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('exam', 'edit')" @click.prevent="openDialog(exam)" >
                        <a class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-edit"></i>
                                </span>
                          <span class="navi-text">Edit</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('exam', 'edit')">
                        <a  @click.prevent="openLinkExamDialog(exam)"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-link"></i>
                                </span>
                          <span class="navi-text">Link exams</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('exam', 'edit')" >
                        <a @click.prevent="openAdditionalAssessmentsDialog(exam)"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-cogs"></i>
                                </span>
                          <span class="navi-text">Additional assessments</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('exam', 'edit')" >
                        <a @click="openCertificateSettingsDialog(exam)"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa-solid fa-certificate"></i>
                                </span>
                          <span class="navi-text">Marksheet settings</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('exam', 'delete') && exam && !exam.is_exam_confirmed">
                        <a
                           @click.prevent="deleteExam(exam.id)"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-trash"></i>
                                </span>
                          <span class="navi-text">Delete</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>
                </div>
              </v-card-title>
              <v-card-text>
                <div class="row">
                  <div class="col-md-12 mb-5 border border-light-grey" style="min-height: 150px"
                       v-if="exam && exam.pending_jobs">
                    <h6>Pending jobs</h6>
                    <v-alert border="top"
                             color="red lighten-2"
                             dark
                             type="info">Exam result calculation job has initiated. It will take time to calculate
                      result
                    </v-alert>
                  </div>
                  <div class="col-md-12 mb-5 border border-light-grey" style="min-height: 150px">
                    <h6>Exam actions</h6>
                    <p>Select actions below to publish timetable to student and mark exam as
                      completed</p>
                    <a v-if="exam && !exam.is_exam_confirmed && checkIsAccessible('exam', 'mark-as-complete')" href="#"
                       class="btn btn-success ml-2 mt-2"
                       @click.prevent="markAs(exam.id, 'confirmed')">
                                                                <span class="navi-icon">
                                                                  <i class="fa fa-user-check"></i>
                                                                </span>
                      <span class="text-white">   Mark as confirmed</span>
                    </a>
                    <v-chip v-if="exam && exam.is_exam_confirmed"
                            class="ml-2 mt-2"  color="orange"
                            @click.prevent="markAs(exam.id, 'not_confirmed')">
                      <i class="fa fa-user-check" style="color: black"></i> &nbsp;&nbsp;Mark as not confirmed
                    </v-chip>
                    <v-chip v-if="exam &&  exam.is_exam_confirmed  && exam.exam_status != 'completed'"
                            class="ml-2 mt-2"  color="primary"
                            @click.prevent="markAs(exam.id, 'completed')">
                      <i class="fa fa-check" style="color: black"></i> &nbsp;&nbsp;Mark as completed
                    </v-chip>
                    <v-chip v-if="exam &&  exam.is_exam_confirmed && exam.exam_status == 'completed'"
                            class="ml-2 mt-2"  color="primary"
                            @click.prevent="markAs(exam.id, 'pending')">
                      <i class="fa fa-check" style="color: black"></i> &nbsp;&nbsp;Mark as incomplete
                    </v-chip>
                    <template v-if="exam &&  exam.is_exam_confirmed">
                      <v-chip v-if="exam && (!exam.exam_status || exam.exam_status == 'pending')"
                              class="ml-2 mt-2"  color="pink"
                              @click.prevent="markAs(exam.id, 'cancelled')">
                        <i class="fa fa-window-close" style="color: black"></i> &nbsp;&nbsp;Mark as cancelled
                      </v-chip>
                    </template>

                  </div>

<!--                  <div v-if="exam &&  exam.is_exam_confirmed" class="col-md-12 mb-5 border border-light-grey"-->
<!--                       style="min-height: 150px">-->
<!--                    <h6>Marking & calculation</h6>-->
<!--                    <p>Click on this button to calculate percentage or gpa obtained by individual-->
<!--                      student. This will calculate total for all students in this exam.</p>-->

<!--                    <router-link-->
<!--                        :to="{ name: 'results-view-all-courses-summary-students' , params: {examId: exam.id}}"-->
<!--                        class="btn btn-primary ml-2 mt-2">-->
<!--                        <span class="navi-icon">-->
<!--                        <i class="fa fa-edit"></i>-->
<!--                      </span>-->
<!--                      <span class="navi-text" v-if="exam">Enter overall result</span>-->
<!--                    </router-link>-->

<!--                    <v-chip class="ml-2 mt-2"  color="orange"-->
<!--                            @click.prevent="calculateTotal(exam)">-->
<!--                      <i class="fa fa-check" style="color: black"></i> &nbsp;&nbsp;{{-->
<!--                        exam.is_total_calculated ? 'Re-Calculate' : 'Calculate'-->
<!--                      }} Total-->
<!--                    </v-chip>-->
<!--                    <v-chip class="ml-2 mt-2"  color="orange"-->
<!--                            @click.prevent="calculateAllCourseResult(exam)">-->
<!--                      <i class="fa fa-check" style="color: black"></i> &nbsp;&nbsp;Re-calculate all course result-->
<!--                    </v-chip>-->
<!--                    <span @click="openImportResult(exam.id)"-->
<!--                          class="btn btn-outline-info ml-2 mt-2">-->
<!--                              <span class="navi-icon">-->
<!--                                <i class="fa fa-file-csv"></i>-->
<!--                              </span>-->
<!--                              <span class="navi-text">Import Result</span>-->
<!--                            </span>-->
<!--                    <span @click="downloadLedger(exam.id)"-->
<!--                          class="btn btn-outline-success ml-2 mt-2">-->
<!--                              <span class="navi-icon">-->
<!--                                <i class="fa fa-file-csv"></i>-->
<!--                              </span>-->
<!--                              <span class="navi-text">Download ledger</span>-->
<!--                            </span>-->
<!--                  </div>-->
                  <div v-if="exam &&  exam.is_exam_confirmed" class="col-md-12 mb-5 border border-light-grey"
                       style="min-height: 150px">
                    <h6>Exam rank</h6>
                    <v-row>
                      <v-col cols="4">
                        <v-select :items="rank_types" item-text="title" item-value="value" outlined dense
                                  label="Rank Type" v-model="exam.generate_sequential_rank"
                                  @change="updateRank(exam.generate_sequential_rank)"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </div>
<!--                  <div v-if="exam &&  exam.is_exam_confirmed" class="col-md-12 mb-5 border border-light-grey"
                       style="min-height: 150px">
                    <h6>Result certificate setting</h6>
                    <p></p>
                    <result-certificate v-if="exam.type == 'terminal'" :exam="exam"
                                        @update-result-certificate="getExam"></result-certificate>
                    <span v-else>
                    No settings are available for board exam
                  </span>
                  </div>-->

                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-dialog
        v-model="loading"
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Processing......
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--    Result Import-->
    <v-dialog
        v-model="dialog"
        width="900"
        title="Manage Result"
    >
      <v-card>
        <v-card-title>
          Import Result
        </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <v-file-input outlined dense v-model="excel_file"
                            accept=".xlsx, .xls, .csv"
                            label="Result File"
              ></v-file-input>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="btn btn-danger text-white cancel-btn"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn :loading="isLoading"
                 class="btn btn-primary text-white"
                 text
                 @click="importResult"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    Attendance Import-->
    <v-dialog
        v-model="attendance_dialog"
        width="900"
        title="Manage Result"
    >
      <v-card>
        <v-card-title>
          Import attendance
        </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <v-file-input outlined dense v-model="attendance_file"
                            accept=".xlsx, .xls, .csv"
                            label="Attendance File"
              ></v-file-input>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="btn btn-danger text-white"
              text
              @click="attendance_dialog = false; attendance_file=null"
          >
            Close
          </v-btn>
          <v-btn :loading="isLoading"
                 class="btn btn-primary text-white"
                 text
                 @click="importAttendance"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <create-and-update ref="exam" @refresh="getExam"></create-and-update>
    <link-exams ref="link-exams" @refresh="getExam"></link-exams>
    <additional-assessments ref="additional-assessments" @refresh="getExam"></additional-assessments>
    <certificate-settings ref="certificate-settings" @refresh="getExam"></certificate-settings>
    <!--            <courses-timetable ref="courseTimetable" @refresh="getExams"></courses-timetable>-->
  </v-app>
</template>

<script>
import CreateAndUpdate from "./CreateAndUpdate";
import Student from "./summary/Students";
import CoursesTimetable from "./CoursesTimetable";
import ExamService from "@/core/services/exam/ExamService";
import ResultCertificate from "@/view/pages/exams/summary/ResultCertificate";
import ExamDashboard from "@/view/pages/exams/summary/Dashboard";

import courses from "./summary/courses";
import Timetable from "./summary/Timetable";
import {API_URL} from "@/core/config";
import StudentResultService from "@/core/services/user/student/result/StudentResultService";
import LinkExams from "./LinkExams";
import AdditionalAssessments from "./AdditionalAssessments";
import CertificateSettings from "./CertificateSettings";

const examService = new ExamService();
const resultService = new StudentResultService();

export default {
  name: "exams-summary-page",
  components: {
    courses,
    Timetable,
    Student,
    CreateAndUpdate,
    LinkExams,
    AdditionalAssessments,
    CertificateSettings,
    ResultCertificate,
    ExamDashboard
  },
  data() {
    return {
      search: {
        academic_year: "",
        program_id: "",
        grade_id: "",
        type: "",
        status: "all",
        start_date: [],
      }, rank_types: [
        {
          title: 'Serial',
          value: 1,
        },
        {
          title: 'Discrete',
          value: 0,
        },
      ],
      types: [
        {
          text: 'Terminal',
          value: 'terminal',
        },
        {
          text: 'Class Test',
          value: 'class_test',
        },
        {
          text: 'Board',
          value: 'board',
        },
      ],
      excel_file: null,
      attrs: false,
      statuses: [
        {
          text: 'All',
          value: 'all',
        }, {
          text: 'Upcoming',
          value: 'upcoming',
        },
        {
          text: 'Past',
          value: 'past',
        },
        {
          text: 'Exam completed',
          value: 'completed',
        },
        {
          text: 'Exam cancelled',
          value: 'cancelled',
        },
        {
          text: 'Exam terminated',
          value: 'terminated',
        },
        {
          text: 'Exam taken',
          value: 'taken',
        },
        {
          text: 'Result published',
          value: 'publish',
        },
        {
          text: 'Result pending',
          value: 'pending',
        },

      ],
      students: [],
      academicYears: [],
      academic_classes: [],
      programs: [],
      gradesLevels: [],
      exams: [],
      courses: [],
      exam_courses: [],
      exam: null,
      academic_id: null,
      model: null,
      previousExamsDialog: false,
      isLoading: false,
      dialog: false,
      menu2: false,
      loading: false,
      menu: false,
      page: null,
      total: null,
      isBusy: false,
      load_notify: false,
      attendance_dialog: false,
      perPage: null,
      attendance_file: null
    };
  },
  mounted() {
    this.getExam();
    this.getAssignedCourses();
  },
  computed: {
    examId() {
      return this.$route.params.exam_id;
    },
  },
  methods: {
    importResult() {
      this.isLoading = true
      let fd = new FormData();
      fd.append("file", this.excel_file);
      examService.importResult(this.examId, fd).then(response => {
        this.isLoading = false
        this.excel_file = null;
        this.$snotify.success("Information imported")
        this.examId = null;
        this.dialog = false
        this.getExam();
      }).catch(error => {
        this.isLoading = false
        this.$snotify.error("Something went wrong please try again later")
      })
    },
    getGrades() {
      gradeLevelService.getByProgram(this.search.program_id).then(response => {
        this.gradesLevels = response.data;
      });
    },
    getPrograms() {
      programService.all().then(response => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.paginate().then(response => {
        this.academicYears = response.data.data;

      });
    },

    getAssignedStudents() {
      examService.getAssignedStudents(this.examId).then(response => {
        this.students = response.data.students;
      });
    },
    getAcademicClasses() {
      academicClassService.paginate().then(response => {
        // this.exams = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },
    getAssignedCourses() {
      examService.getAssignedPivotCourses(this.examId).then(response => {
        this.exam_courses = response.data.courses;
        if (this.exam && this.exam.has_additional_assessment)
          this.$refs['course'].checkForAdditionalAssessment()
      });
    },
    getAssignedCoursesTimetable() {
      examService.getAssignedCourses(this.examId).then(response => {
        this.exam_courses = response.data.courses;
      });
    },
    reset() {
      this.search = {
        academic_year: "",
        program_id: "",
        grade_id: "",
        type: "",
        status: "all",
        start_date: [],
      }
      this.getExam();
    },
    getExam() {
      this.isBusy = true;
      examService
          .show(this.examId)
          .then(response => {
            this.exam = response.data.exam;
        // this.getAssignedStudents();
        //this.getAssignedCoursesTimetable();
        })
          .catch((err) => {
        // console.log(err)
        }).finally(() => {
          this.isBusy = false;
        });
    },
    openDialog(exam) {
      this.$refs["exam"].showModal(exam);
    },

    openLinkExamDialog(exam)
    {
      this.$refs["link-exams"].showModal(exam);
    },

    openAdditionalAssessmentsDialog(exam)
    {
      this.$refs["additional-assessments"].showModal(exam);
    },

    openCertificateSettingsDialog(exam)
    {
      this.$refs["certificate-settings"].showModal(exam);
    },

    assignCourse(exam) {
      this.$refs["course"].showModal(exam);
    },
    updateRank(rank) {
      // this.exam.generate_sequential_rank = !rank
      this.updateExam();
    }, updateResultCertificateSetting(exam) {
      this.exam = exam
      this.updateExam();
    },
    updateExam() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            examService.update(this.exam.id, this.exam).then(response => {
              this.$snotify.success("Information updated");
              this.getExam();
            });
          } else {
            this.getExam()
          }
        }
      });
    }, deleteExam(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            examService.delete(id).then(response => {
              this.$snotify.success("Information deleted");
              this.$tabs.close().then(response => {
                this.$router.push({name: "exams"})
              });
            });
          }
        }
      });
    },
    calculateTotal(exam) {
      examService.calculateTotal(exam.id).then(response => {
        if(response.data.status=='OK')
        {
          this.$snotify.success("Total calculation has been scheduled.");
        }
        else{
          this.$snotify.warning(response.data.msg);
        }

        this.getExam();
      });
    },
    calculateAllCourseResult(exam) {
      exam.calculate_total = 1;
      examService.recalculateAllCourse(exam.id, exam).then(response => {
        this.$snotify.success("Course result calculation initiated.");
        this.getExam();
      });
    },
    markAs(id, type) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            this.loading = true;
            examService.markAs(id, type).then(response => {
              this.$snotify.success("Information updated");
              this.getExam();
              this.loading = false;
            }).catch(() => {}).finally(() => {
              this.loading = false;
            });
          }
        }
      });
    },
    openImportResult(id) {
      this.dialog = true
      this.examId = id;

    },
    downloadLedger(examId) {
      window.open(
          API_URL + "user/download/exam/ledger/" + examId,
          "_blank"
      );
    },
    exportAttendanceLedger(examId) {
      window.open(
          API_URL + "user/download/exam/attendance/ledger/" + examId,
          "_blank"
      );
    }, importAttendanceLedgerDialog() {
      this.attendance_dialog = true;
    }, importAttendance() {
      let formData = new FormData();
      formData.append('file', this.attendance_file);
      this.$bus.emit('toggleLoader',);
      resultService.importAttendance(this.examId, formData).then(response => {
        this.$bus.emit('toggleLoader');
        this.attendance_dialog = false
        this.attendance_file = null
        this.$snotify.success("Information imported")
      }).catch(error => {
        this.attendance_dialog = false
        this.attendance_file = null
        this.$bus.emit('toggleLoader');
        this.$snotify.error("Something went wrong. Please try again later")
      })
    },
    notifyStudents(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            this.load_notify = true
            this.$emit("toogle_overlay");
            examService.notifyStudents(id).then(response => {
              this.$snotify.success("Information updated")
              this.load_notify = false
              this.$emit("toogle_overlay");
            }).catch(() => {
              this.load_notify = false
              this.displayMessage("error")
              this.$emit("toogle_overlay");
            })
          }
        }
      });
    },
    sendExamResultConfirmedEmail(exam) {
      this.$confirm({
        message: `Are you sure you want to send exam confirmed mail?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            examService.sendExamResultConfirmedEmail(exam.id).then(response => {
              this.$snotify.success('Request has been processed');
            }).catch(error => {
              this.$snotify.error('Something went wrong');
            })
          }
        }
      });

    }, sendExamResultPublishedEmail(exam) {
      this.$confirm({
        message: `Are you sure you want to send exam published mail?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            examService.sendExamResultPublishedEmail(exam.id).then(response => {
              this.$snotify.success('Request has been processed');
            }).catch(error => {
              this.$snotify.error('Something went wrong');
            })
          }
        }
      });

    },
  }
}
</script>

<style scoped>
.menu-parent .v-tab {
  justify-content: flex-start !important;
}
</style>
