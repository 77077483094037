<template>
  <v-app>
    <v-row>
      <v-col cols="3">
        <v-switch label="Show program" :disabled="disableSwitch(exam.type, 'show_program')" v-model="exam.show_program">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Symbol No." :disabled="disableSwitch(exam.type, 'show_symbol_no')"
                  v-model="exam.show_symbol_no">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Shift" :disabled="disableSwitch(exam.type, 'show_shift')" v-model="exam.show_shift">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Class" :disabled="disableSwitch(exam.type, 'show_class')" v-model="exam.show_class">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Section" :disabled="disableSwitch(exam.type, 'show_section')" v-model="exam.show_section">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Exam" :disabled="disableSwitch(exam.type, 'show_exam')" v-model="exam.show_exam">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Academic year" :disabled="disableSwitch(exam.type, 'show_academic_year')"
                  v-model="exam.show_academic_year">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Subject code" :disabled="disableSwitch(exam.type, 'show_subject_code')"
                  v-model="exam.show_subject_code">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Credit hour" :disabled="disableSwitch(exam.type, 'show_credit_hour')"
                  v-model="exam.show_credit_hour">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Full marks" :disabled="disableSwitch(exam.type, 'show_full_marks')"
                  v-model="exam.show_full_marks">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Pass marks" :disabled="disableSwitch(exam.type, 'show_pass_marks')"
                  v-model="exam.show_pass_marks">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Marks obtained" :disabled="disableSwitch(exam.type, 'show_marks_obtained')"
                  v-model="exam.show_marks_obtained">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Grade Point" :disabled="disableSwitch(exam.type, 'show_grade_point')"
                  v-model="exam.show_grade_point">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Highest marks" :disabled="disableSwitch(exam.type, 'show_highest_marks')"
                  v-model="exam.show_highest_marks">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Subject grades"
                  v-model="exam.show_subject_grades">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Final grade point"
                  v-model="exam.show_final_grade">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Remarks"
                  v-model="exam.show_remarks">
        </v-switch>
      </v-col>


      <v-col cols="3">
        <v-switch label="Previous marks" :disabled="disableSwitch(exam.type, 'show_previous_marks')"
                  v-model="exam.show_previous_marks">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Percentage" :disabled="disableSwitch(exam.type, 'show_percentage')"
                  v-model="exam.show_percentage">
        </v-switch>
      </v-col>
      <v-col cols="3">
        <v-switch label="GPA" :disabled="disableSwitch(exam.type, 'show_gpa')" v-model="exam.show_gpa">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Rank" :disabled="disableSwitch(exam.type, 'show_rank')" v-model="exam.show_rank">
        </v-switch>
      </v-col>
      <v-col cols="3">
        <v-switch label="Attendance" :disabled="disableSwitch(exam.type, 'show_attendance')"
                  v-model="exam.show_attendance">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Overall grade point"
                  v-model="exam.show_overall_grade_point">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Additional assessment" :disabled="disableSwitch(exam.type, 'has_additional_assessment')"
                  v-model="exam.has_additional_assessment">
        </v-switch>
      </v-col>
      <v-col cols="3">
        <v-switch label="Internal evaluation" :disabled="disableSwitch(exam.type, 'show_internal_evaluation')"
                  v-model="exam.show_internal_evaluation">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Show criterion text" :disabled="disableSwitch(exam.type, 'show_criterion_text')"
                  v-model="exam.show_criterion_text">
        </v-switch>
      </v-col>


      <v-col cols="3">
        <v-switch label="Date of issue" :disabled="disableSwitch(exam.type, 'show_date_of_issue')"
                  v-model="exam.show_date_of_issue">
        </v-switch>
      </v-col>
      <v-col cols="3">
        <v-switch label="Class teacher" :disabled="disableSwitch(exam.type, 'show_class_teacher')"
                  v-model="exam.show_class_teacher">
        </v-switch>
      </v-col>
      <v-col cols="3">
        <v-switch label="Coordinator" :disabled="disableSwitch(exam.type, 'show_coordinator')"
                  v-model="exam.show_coordinator">
        </v-switch>
      </v-col>
      <v-col cols="3">
        <v-switch label="School seal" :disabled="disableSwitch(exam.type, 'show_seal')" v-model="exam.show_seal">
        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="Principal" :disabled="disableSwitch(exam.type, 'show_principal')" v-model="exam.show_principal">
        </v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-switch label="Registration No."  v-model="exam.show_registration_no">

        </v-switch>
      </v-col>

      <v-col cols="3">
        <v-switch label="DOB"
                  v-model="exam.show_dob">

        </v-switch>
      </v-col>

    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select
            label="Result certificate"
            v-model="result_certificate"
            :items="templates"
            item-text="file_name"
            item-value="id"
            @change="updateResultCertificate"
            return-object
            clearable
            outlined
            dense
        >
        </v-select>
      </v-col>

      <v-col cols="6" v-if="url">
        <v-btn
            class="primary"
            @click="previewResultCertificate"
            dark
        >
          Preview
        </v-btn>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import ExamService from "@/core/services/exam/ExamService";
import TemplatesService from "@/core/services/templates/TemplatesService";

const examService = new ExamService();
const template = new TemplatesService();
export default {
  name: "ResultCertificate",
  props: ['exam'],
  data() {
    return {
      templates: [],
      url: null,
      result_certificate: {},
      terminal_switch: [
        "show_program",
        "show_symbol_no",
        "show_shift",
        "show_class",
        "show_section",
        "show_exam",
        "show_academic_year",
        "show_subject_code",
        "show_credit_hour",
        "show_full_marks",
        "show_pass_marks",
        "show_marks_obtained",
        "show_grade_point",
        "show_subject_grades",
        "show_highest_marks",
        "show_previous_marks",
        "show_percentage",
        "show_gpa",
        "show_overall_grade_point",
        "show_rank",
        "show_attendance",
        "has_additional_assessment",
        "show_internal_evaluation",
        "show_criterion_text",
        "show_date_of_issue",
        "show_class_teacher",
        "show_coordinator",
        "show_seal",
        "show_principal",
      ],
      board_switch: [
        "show_symbol_no",
        "show_class",
        "show_subject_code",
        "show_credit_hour",
        "show_full_marks",
        "show_pass_marks",
        "show_marks_obtained",
        "show_grade_point",
        "show_subject_grades",
        "show_highest_marks",
        "show_previous_marks",
        "show_percentage",
        "show_gpa",
        "show_overall_grade_point",
        "show_rank",
        "show_attendance",
        "has_additional_assessment",
        "show_internal_evaluation",
        "show_criterion_text",
        "show_date_of_issue",
        "show_class_teacher",
        "show_coordinator",
        "show_seal",
        "show_principal",
      ],
    }
  },
  mounted() {
    this.getAllResultTemplate();
  },
  methods: {
    resetForm() {
      this.result_certificate = null;
      this.url = null;
    },

    updateResultCertificate() {
      if(this.result_certificate) {
        this.exam.result_certificate_id = this.result_certificate.id;
        this.url = this.result_certificate.image_path['real'];
      } else {
        this.exam.result_certificate_id = null;
        this.url = null;
      }
    },

    previewResultCertificate() {
      if(this.url) {
        window.open(this.url, 'blank');
      }
    },

    disableSwitch(examType, switchType) {
      if (examType == "terminal") {
        return this.terminal_switch.indexOf(switchType) <= 0 ? true : false;
      }
      if (examType == "board") {
        return this.board_switch.indexOf(switchType) <= 0 ? true : false;
      }
      return false;

    },
    getAllResultTemplate() {
      template.getByType(this.exam.program_id,'result_certificate').then(response => {
        this.templates = response.data.data;
        if(this.exam && this.exam.result_certificate) {
          this.result_certificate = this.exam.result_certificate;
          this.url = this.exam.result_certificate.image_path || null;
        }
      }).catch(() => {})
    }
  }
}
</script>
