<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Exam students</h4>
              </div>
              <div
                class="breadcrumb-right"
                v-if="selected.length > 0 && exam.exam_status == 'completed'"
              >
                <div class="card-toolbar">
                  <v-btn
                    @click="notifyStudents()"
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon
                    >&nbsp; Notify result
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="">
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th
                      class=""
                      style="max-width: 100px !important; white-space: pre-wrap"
                    >
                      <v-checkbox
                        class=""
                        @change="selectAllStudent()"
                      ></v-checkbox>
                    </th>
                    <th
                      class=""
                      style="max-width: 200px !important; white-space: pre-wrap"
                    >
                      Student
                    </th>
                    <th class="text-left">Result</th>
                    <th class="text-left">Rank</th>
                    <th class="text-center">Status</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-show="result_students.length > 0"
                    v-for="(student, index) of result_students"
                    :key="index"
                  >
                    <td
                      style="max-width: 100px !important; white-space: pre-wrap"
                    >
                      <v-checkbox
                        :value="student.student_id"
                        v-model="selected"
                      ></v-checkbox>
                    </td>
                    <td
                      style="max-width: 200px !important; white-space: pre-wrap"
                    >
                      <a
                        @click="studentSummary(student.student_id)"
                        class="text-primary font-weight-bolder text-hover-primary mb-1"
                      >
                        {{ student.name ? student.name : "-" }} </a
                      ><br />

                      <b>CRN No.:</b> {{ student.crn_no }}<br />
                      <b v-if="student.midas_id">External ID:</b>
                      {{ student.midas_id }} <br v-if="student.midas_id" />
                      <b>Exam symbol No.:</b> {{ student.exam_symbol_no }}
                      <br />
                    </td>
                    <!--                  <td class=" text-left" style="max-width: 100px !important; white-space: pre-wrap;">-->
                    <!--                     <span class="font-weight-medium">-->
                    <!--                      {{ student.class ? student.class : '-' }}-->
                    <!--                     </span>-->
                    <!--                  </td>-->
                    <td class="">
                      <b>Total mark:</b>
                      <span class="font-weight-medium">
                        {{
                          student.total_obtained_mark
                            ? student.total_obtained_mark
                            : "NA "
                        }}
                      </span>
                      <br />
                      <b>Total grade:</b>
                      <span class="font-weight-medium">
                        {{
                          student.total_grade_point
                            ? student.total_grade_point
                            : "NA"
                        }} </span
                      ><br />
                      <b>GPA:</b>
                      <span class="font-weight-medium">
                        {{ student.total_gpa ? student.total_gpa : "NA" }}
                      </span>
                      <br />
                      <span class="font-weight-medium">
                        <b>Attendance:</b>
                        <span class="font-weight-medium">
                          {{ student.attendance ? student.attendance : "NA" }}
                        </span>
                      </span>
                      <br />
                      <b>Division:</b>
                      <span class="font-weight-medium">
                        {{ student.division ? student.division : "NA" }}
                      </span>
                    </td>
                    <td class="text-center">
                      <span
                        class="badge badge-info count_number_badge"
                        v-if="student.rank"
                        v-bind:style="{
                          background: student.rank_color + '!important',
                        }"
                      >
                        {{ student.rank ? student.rank : "NA" }}
                      </span>
                      <span
                        class="badge badge-info count_number_badge_depressed"
                        v-else
                      >
                        {{ student.rank ? student.rank : "NA" }}
                      </span>
                    </td>
                    <td>
                      <div
                        v-if="student.is_exam_notified"
                        class="badge badge-success"
                      >
                        Result notified
                      </div>
                      <div class="badge badge-warning" v-else>
                        Result not notified
                      </div>

                      <div
                        class="badge mt-1"
                        title="Exam status"
                        :class="
                          student.exam_status
                            ? student.exam_status == 'completed'
                              ? 'badge-success'
                              : 'badge-warning'
                            : 'badge-warning'
                        "
                      >
                        <span v-if="student.exam_status == 'completed'"
                          >Exam completed</span
                        >
                        <span v-else>Exam pending</span>
                      </div>
                      <div
                        class="badge mt-1"
                        title="Result status"
                        :class="
                          student.result_status == 'published'
                            ? 'badge-success'
                            : student.result_status == 'completed'
                            ? 'badge-primary'
                            : 'badge-warning'
                        "
                        v-if="student.result_status"
                      >
                        <span v-if="student.result_status == 'published'"
                          >Result published</span
                        >
                        <span v-else-if="student.result_status == 'completed'"
                          >Result completed</span
                        >
                        <span v-else-if="student.result_status == 'completed'"
                          >Result pending</span
                        >
                      </div>

                      <div
                        class="badge mt-1"
                        title="Result"
                        :class="
                          student.result
                            ? student.is_pass
                              ? 'badge-success'
                              : 'badge-danger'
                            : 'badge-danger'
                        "
                        v-if="student.result"
                      >
                        <span v-if="student.is_pass">Pass</span>
                        <span v-else>Fail</span>
                      </div>

                      <div
                        class="badge mt-1"
                        title="Admit card printed"
                        :class="
                          student.is_admit_card_printed
                            ? 'badge-info'
                            : 'badge-warning'
                        "
                        v-if="student"
                      >
                        <span v-if="student.is_admit_card_printed"
                          >Admit card printed</span
                        >
                        <span v-else>Admit card not printed</span>
                      </div>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="student.result"
                            >
                              <a
                                @click="
                                  openResultPdf(
                                    exam.id,
                                    student.student_id,
                                    'certificate'
                                  )
                                "
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <i class="fas fa-file-pdf"></i>
                                </span>
                                <span class="navi-text"
                                  >Online exam certificate (PDF)</span
                                >
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="student.result"
                            >
                              <a
                                @click="
                                  openResultPdf(
                                    exam.id,
                                    student.student_id,
                                    'print'
                                  )
                                "
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <i class="fas fa-file-pdf"></i>
                                </span>
                                <span class="navi-text"
                                  >Preview print certificate(PDF)</span
                                >
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a
                                @click="
                                  openAdmitCardPdf(exam.id, student.student_id)
                                "
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <i class="fas fa-file-pdf"></i>
                                </span>
                                <span class="navi-text"
                                  >Admit-card on hold</span
                                >
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a
                                @click="
                                  openAdmitCardPdf(exam.id, student.student_id)
                                "
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <i class="fas fa-file-pdf"></i>
                                </span>
                                <span class="navi-text">View admit card</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="result_students.length == 0">
                    <td colspan="11" class="text-center">
                      No students available
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!--        <div class="row" v-if="selected.length>0">-->
            <!--          <div class="col-4">-->
            <!--            <v-select-->
            <!--                outlined-->
            <!--                dense-->
            <!--                label="Result Status"-->
            <!--                v-model="status.result_status"-->
            <!--                :items="['completed','published', 'pending']"-->
            <!--            >-->
            <!--            </v-select>-->
            <!--          </div>-->
            <!--          <div class="col-4">-->
            <!--            <v-select-->
            <!--                outlined-->
            <!--                dense-->
            <!--                label="Exam Status"-->
            <!--                v-model="status.exam_status"-->
            <!--                :items="['completed','pending']"-->
            <!--            >-->
            <!--            </v-select>-->
            <!--          </div>-->
            <!--          <div class="col-4">-->
            <!--            <v-select-->
            <!--                v-model="status.result"-->
            <!--                outlined-->
            <!--                dense-->
            <!--                label="Result Status"-->
            <!--                :items="['passed','failed']"-->
            <!--            >-->

            <!--            </v-select>-->
            <!--          </div>-->
            <!--        </div>-->
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import ExamService from "@/core/services/exam/ExamService";
import StudentResultService from "@/core/services/user/student/result/StudentResultService";
import { API_URL, APP_URL } from "@/core/config";

const resultService = new StudentResultService();
const examService = new ExamService();
export default {
  name: "Students",
  props: ["exam"],
  data() {
    return {
      status: {
        result_status: null,
        exam_status: null,
        result: null,
      },
      result_students: [],
      selected: [],
      newColorarray: [],
      headers: [
        { text: "CRN No.", value: "crn_no" },
        { text: "Name", value: "name" },
      ],
    };
  },
  mounted() {
    this.getAssignedStudents();
  },
  methods: {
    studentSummary(studentId) {
      this.$tabs.open({
        name: "students-summary",
        params: { id: studentId },
      });
    },
    getAssignedStudents() {
      // console.log('fetching')

      this.$bus.emit("toggleLoader");
      examService
        .getAssignedStudents(this.exam.id)
        .then((response) => {
          this.selectedStudents = response.data.students;
          this.result_students = this.selectedStudents.map((student) => {
            return {
              student_id: student.student_id,
              exam_symbol_no: student.exam_symbol_no,
              crn_no: student.crn_no,
              name: student.name,
              current_class: student.current_class,
              email: student.email,
              result_added_by: null,
              exam_id: this.examId,
              exam_course_id: null,
              total_obtained_mark: student.total_obtained_mark,
              total_gpa: student.total_gpa,
              rank: student.rank,
              rank_color: student.rank_color,
              division: student.division,
              result_status: student.result_status,
              exam_status: student.exam_status,
              result: student.result,
              total_grade_point: student.total_grade_point,
              midas_id: student.midas_id,
              class: student.class,
              attendance: student.attendance,
              is_exam_notified: student.is_exam_notified,
              is_pass: student.is_pass,
              is_admit_card_printed: student.is_admit_card_printed,
            };
          });
          // this.getResults();
        })
        .catch((error) => {})
        .finally(() => {
          this.$bus.emit("toggleLoader");
        });
    },
    selectAll() {
      if (this.selected.length > 0) {
        this.selected = [];
      } else {
        for (let index in this.result_students) {
          this.selected.push(parseInt(index));
        }
      }
    },
    openResultSummaryDialog(result) {
      resultService
        .show(result.student_id, result.result_id)
        .then((response) => {
          this.exam_summary = response.data.result;
          this.summary = true;
        });
    },
    getRandomColor(rank) {
      let color = "#";
      for (let i = 0; i < 3; i++) {
        color += (
          "0" + Math.floor((Math.random() * Math.pow(16, 2)) / 2).toString(16)
        ).slice(-2);
      }
      return color;
      // return '#'+(Math.random()*0xFFFFFF<<0).toString(16);
    },
    openResultPdf(examId, studentId, type) {
      window.open(
        API_URL +
          "user/result/session/student/detail/" +
          examId +
          "/" +
          studentId +
          "/" +
          type,
        "_blank"
      );
    },
    openAdmitCardPdf(examId, studentId) {
      window.open(
        API_URL + "user/exams/" + examId + "/admitcard/preview/" + studentId,
        "_blank"
      );
    },
    saveStudentResult() {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$bus.emit("toggleLoader");
            this.changeStatus();
            this.is_loading = true;
            resultService
              .saveStudentResult(this.exam.id, this.result_students)
              .then((response) => {
                this.$snotify.success("Information updated");
                this.is_loading = false;
                this.getAssignedStudents();
              })
              .catch((error) => {
                // console.log(error)
              })
              .finally(() => {
                this.is_loading = false;
                this.$bus.emit("toggleLoader");
              });
          }
        },
      });
    },
    changeStatus() {
      this.selected.forEach((ele) => {
        this.result_students[ele].exam_status = this.status.exam_status
          ? this.status.exam_status
          : this.result_students[ele].exam_status;
        this.result_students[ele].result_status = this.status.result_status
          ? this.status.result_status
          : this.result_students[ele].result_status;
        this.result_students[ele].result = this.status.result
          ? this.status.result
          : this.result_students[ele].result;
      });

      this.status = {
        result_status: null,
        exam_status: null,
        result: null,
      };
    },
    selectAllStudent() {
      if (this.selected.length > 0) {
        this.selected = [];
        return false;
      }
      if (this.result_students && this.result_students.length > 0) {
        this.result_students.forEach((ele) => {
          this.selected.push(ele.student_id);
        });
        return false;
      }
    },
    notifyStudents(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.load_notify = true;
            this.$emit("toogle_overlay");
            let data = { ids: this.selected };
            examService
              .notifyStudents(this.exam.id, data)
              .then((response) => {
                this.$snotify.success("Information updated");
                this.load_notify = false;
                this.$emit("toogle_overlay");
                this.selected = [];
              })
              .catch(() => {
                this.load_notify = false;
                this.displayMessage("error");
                this.$emit("toogle_overlay");
              });
          }
        },
      });
    },
  },
};
</script>

<style scoped>
</style>
